<template>
  <span v-if="isAuthenticated">
    <a href @click.prevent="showModal" class="top-bar-button">
      <i class="fa fa-gear"></i> <span class="top-bar-button-text">Préférences</span>
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      :ok-disabled="formInvalid"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-gear"></i> Préférences Utilisateur</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
         <b-col>
          <b-row>
            <b-col>
              <b-form-group
                label-for="prefSchoolYears"
                label="Sélectionnez les années scolaires que vous souhaitez rendre actives"
              >
                <check-box-select
                  id="prefSchoolYears"
                  :choices="schoolYears"
                  :initial-value="initialSchoolYears"
                  @changed="onSelectedYearsChanged($event)"
                >
                </check-box-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="logoutOnClose"
            label="Déconnexion automatique"
          >
            <b-checkbox
              id="logoutOnClose"
              v-model="logoutOnClose"
            >
              Déconnexion lors de la fermeture du navigateur
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="autoLogout-range"
            label="Durée d'inactivité avant déconnexion"
            :description="autoLogoutDescription"
          >
            <b-form-input
              id="autoLogout-range"
              v-model="autoLogout"
              type="range"
              min="0"
              max="60"
              step="5"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { makeUserPreferences } from '@/types/users'
import { getRandomId } from '@/utils/random'
import { BackendApi, isAuthenticated } from '@/utils/http'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { loadPreferences } from '@/apis/preferences'

export default {
  name: 'user-preferences-modal',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: null,
      randomId: getRandomId(),
      errorText: '',
      schoolYears: [],
      selectedSchoolYears: [],
      autoLogout: 0,
      logoutOnClose: false,
      isAuthenticated: false,
    }
  },
  mounted() {
    loadPreferences()
    this.isAuthenticated = isAuthenticated()
  },
  computed: {
    formInvalid() {
      return (
        (this.selectedSchoolYears === this.initialSchoolYears) &&
        (this.autoLogout === this.initialAutoLogout) &&
        (this.logoutOnClose === this.initAutoLogout)
      )
    },
    initialSchoolYears() {
      return store.getters.userPreferences.activeSchoolYears
    },
    initialAutoLogout() {
      return store.getters.userPreferences.autoLogout
    },
    initialLogoutOnClose() {
      return store.getters.userPreferences.logoutOnClose
    },
    autoLogoutDescription() {
      const autoLogout = +this.autoLogout
      if (autoLogout) {
        return '' + autoLogout + ' minute' + ((autoLogout > 1) ? 's' : '')
      } else {
        return 'Déconnexion automatique désactivée'
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
      if (this.id !== 0) {
        this.loadUser()
      }
      this.loadSchoolYears()
      this.autoLogout = this.initialAutoLogout
      this.selectedSchoolYears = this.initialSchoolYears
      this.logoutOnClose = this.initialLogoutOnClose
    },
    getId() {
      return 'bv-modal-edit-user-button' + this.randomId
    },
    onSelectedYearsChanged(event) {
      this.selectedSchoolYears = event.choices
    },
    async onSave() {
      const schoolYears = this.selectedSchoolYears.map(schoolYear => schoolYear.id)
      const data = {
        active_school_years: schoolYears,
        auto_logout: this.autoLogout,
        logout_on_close: this.logoutOnClose,
      }
      let backendApi = new BackendApi('post', '/users/api/user-preferences/')
      try {
        let resp = await backendApi.callApi(data)
        const preference = makeUserPreferences(resp.data)
        await this.addSuccess('Les préférences ont bien été enregistrées')
        this.$bvModal.hide(this.getId())
        await store.commit('setUserPreferences', preference)
        // reload the page
        window.location.reload()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      let backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.group-header {
  font-weight: bold;
  padding-bottom: 3px;
  margin-bottom: 5px;
  border-bottom: solid 1px #000;
}
</style>
