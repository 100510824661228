<template>
  <div class="search" v-if="hasPerm('families.view_family')">
    <vue-bootstrap-typeahead
      v-model="name"
      :data="entities"
      placeholder="Entrez le nom d'une famille ou structure"
      ref="entityTypeahead"
      @hit="onTypeaheadHit"
      :minMatchingChars="3"
      :disableFiltering="true"
      :serializer="entity => (entity.name + ': ' + entity.members)"
      :input-class="selected ? 'filled' : ''"
      :disabled="disabled"
    >
      <template slot="append">
        <b-button @click="onClear" variant="secondary" v-if="!disabled">
          <i class="fa fa-times"></i>
        </b-button>
      </template>
    </vue-bootstrap-typeahead>
  </div>
</template>

<script>
import _ from 'underscore'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeEntity } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'EntityTypeahead',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entities: [],
      name: '',
      selected: null,
    }
  },
  created() {
    this.initEntity(this.initialId)
  },
  watch: {
    name: _.debounce(function(name) { this.searchEntity(name) }, 500),
    initialId: function(value) { this.initEntity(value) },
  },
  methods: {
    ...mapActions(['addError']),
    onClear(evt) {
      evt.preventDefault()
      this.name = ''
      this.entities = []
      this.$refs.entityTypeahead.inputValue = ''
      this.selected = null
      this.$emit('change', { entity: null, })
    },
    async searchEntity(text) {
      if (text) {
        try {
          let url = '/search/api/search-entity/?text=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.entities = await resp.data.map(elt => makeEntity(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onTypeaheadHit(evt) {
      this.selected = evt
      this.$emit('change', { entity: evt, })
    },
    async initEntity(entityId) {
      if (entityId) {
        try {
          let url = '/search/api/search-entity/?text=' + entityId
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          if (resp.data.length) {
            const entity = makeEntity(resp.data[0])
            this.entities = [entity]
            this.$refs.entityTypeahead.inputValue = entity.name
            this.selected = entity
            this.$emit('init', { entity: entity, })
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
