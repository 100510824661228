import {
  Entity, EntityRole, Individual, EntityMembership, makeEntity, makeEntityRole, makeIndividual,
  makeEntityMembership
} from '@/types/people'
import {
  SchoolClass, makeSchoolClass, SchoolLevel, makeSchoolLevel, SchoolLevelGroup, makeSchoolLevelGroup
} from '@/types/schools'
import { existsIn } from '@/utils/arrays'
import { compareDays } from '@/utils/sorting'

export class SpecialTariff {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeSpecialTariff(jsonData: any = null): SpecialTariff {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SpecialTariff(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class WelfareCategory {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeWelfareCategory(jsonData: any = null): WelfareCategory {
  if (jsonData === null) {
    jsonData = {}
  }
  return new WelfareCategory(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Child {
  constructor(
    public id: number,
    public individualId: number,
    public warnings: string,
    public ageGroup: number,
    public ageGroupFrom: Date|null,
    public ageGroupTo: Date|null,
    public schoolClass: SchoolClass|null,
    public schoolLevel: SchoolLevel,
    public schoolLevelGroup: SchoolLevelGroup,
    public schoolClassUpdatedOn: Date|null,
    public discountPercentage: number|null,
    public specialTariff: SpecialTariff,
    public welfareCategory: WelfareCategory,
    public personalHelp: boolean,
    public handicapHelp: boolean,
    public handicapDocument: boolean,
    public handicapHelpStartsOn: Date|null,
    public handicapHelpEndsOn: Date|null,
    public hideSpecialTariff: boolean,
    public hideWelfareCategory: boolean,
    public hideDiscountPercentage: boolean,
    public hideChildWarnings: boolean,
    public hideCustomAgeGroup: boolean,
    public hidePersonalHelp: boolean,
    public hasSchoolLevel: boolean,
    public parents: Individual[]
  ) {
  }

  public schoolClassName(): string {
    if (this.schoolClass) {
      return this.schoolClass.fullName()
    } else {
      return ''
    }
  }

  public hasHandicapHelpOn(date: string): boolean {
    if (this.handicapHelp) {
      if (this.handicapHelpStartsOn) {
        if (compareDays(this.handicapHelpStartsOn, date) < 0) {
          return false
        }
      }
      if (this.handicapHelpEndsOn) {
        if (compareDays(this.handicapHelpEndsOn, date) > 0) {
          return false
        }
      }
      return true
    }
    return false
  }
}

export function makeChild(jsonData: any = null): Child {
  if (jsonData === null) {
    jsonData = {}
  }
  let schoolClass: SchoolClass|null
  if (jsonData.school_class) {
    schoolClass = makeSchoolClass(jsonData.school_class)
  } else {
    schoolClass = makeSchoolClass()
  }
  let schoolLevel: SchoolLevel
  if (jsonData.school_level) {
    schoolLevel = makeSchoolLevel(jsonData.school_level)
  } else {
    schoolLevel = makeSchoolLevel()
  }
  let schoolLevelGroup: SchoolLevelGroup
  if (jsonData.school_level_group) {
    schoolLevelGroup = makeSchoolLevelGroup(jsonData.school_level_group)
  } else {
    schoolLevelGroup = makeSchoolLevelGroup()
  }
  let discountPercentage: number|null = null
  if (jsonData.discount_percentage) {
    discountPercentage = +jsonData.discount_percentage
    if (isNaN(discountPercentage)) {
      discountPercentage = null
    }
  }
  let parents = []
  if (jsonData.parents) {
    parents = jsonData.parents.map((parentData: any) => makeIndividual(parentData))
  }
  return new Child(
    jsonData.id || 0,
    jsonData.individual || 0,
    jsonData.warnings || '',
    jsonData.age_group || null,
    jsonData.age_group_from || null,
    jsonData.age_group_to || null,
    schoolClass,
    schoolLevel,
    schoolLevelGroup,
    jsonData.school_class_updated_on || null,
    discountPercentage,
    makeSpecialTariff(jsonData.special_tariff),
    makeWelfareCategory(jsonData.welfare_category),
    !!jsonData.personal_help,
    !!jsonData.handicap_help,
    !!jsonData.handicap_document,
    jsonData.handicap_help_starts_on || null,
    jsonData.handicap_help_ends_on || null,
    !!jsonData.hide_special_tariff,
    !!jsonData.hide_welfare_category,
    !!jsonData.hide_discount_percentage,
    !!jsonData.hide_child_warnings,
    !!jsonData.hide_custom_age_group,
    !!jsonData.hide_personal_help,
    !!jsonData.has_school_level,
    parents
  )
}

export class FamilyLevel {
  constructor(
    public id: number,
    public value: number|null,
    public updatedOn: Date|null,
    public order: number
  ) {
  }
}

export function makeFamilyLevel(jsonData: any = null, order = 0): FamilyLevel {
  if (jsonData === null) {
    jsonData = {}
  }
  return new FamilyLevel(
    jsonData.id || 0,
    jsonData.value || null,
    jsonData.updated_on || null,
    jsonData.order || order
  )
}

export class SocialRegime {
  constructor(
    public id: number,
    public name: string,
    public isDefault: boolean,
    public proAccess: boolean,
    public proAccessName: string,
    public proAccessUrl: string
  ) {
  }

  public getProAccessName() {
    return this.proAccessName || this.name
  }
}

export function makeSocialRegime(jsonData: any = null): SocialRegime {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SocialRegime(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.is_default,
    !!jsonData.pro_access,
    jsonData.pro_access_name || '',
    jsonData.pro_access_url || ''
  )
}

export class Family {
  constructor(
    public id: number,
    public entity: Entity,
    public cafNumber: string,
    public allowCafProAccess: boolean,
    public personalDataAgreementDate: Date,
    public acceptMeetingsNotificationByEmail: boolean,
    public levels: FamilyLevel[],
    public unconfirmedLevels: FamilyLevel[],
    public socialRegime: SocialRegime,
    public cafUpdateMessage: string,
    public hideMeetingsNotificationByEmail: boolean
  ) {
  }

  public hasFamilyLevel(): boolean {
    if (this.levels.length > 0) {
      return this.levels[0].value !== null
    }
    return false
  }

  public mainLevel(): number|null {
    if (this.levels.length > 0) {
      return this.levels[0].value
    }
    return null
  }
}

export function makeFamily(jsonData: any = null): Family {
  if (jsonData === null) {
    jsonData = {}
  }
  const familyData = jsonData.family || {}
  let levels = familyData.levels || []
  if (levels.length === 0) {
    levels = [null, null, null]
  }
  let unconfirmedLevels = familyData.unconfirmed_levels || []
  const entity = makeEntity(jsonData || {})
  const family = new Family(
    familyData.id || 0,
    entity,
    familyData.caf_number || '',
    !!familyData.allow_caf_pro_access,
    familyData.rgpd_agreement_date || null,
    !!familyData.accept_meetings_notification_by_email,
    levels.map((elt: any, index: number) => makeFamilyLevel(elt, index + 1)),
    unconfirmedLevels.map((elt: any) => makeFamilyLevel(elt, elt.order)),
    makeSocialRegime(familyData.social_regime),
    familyData.caf_update_message || '',
    !!familyData.hide_meetings_notification_by_email
  )
  entity.family = family
  // assign the child id for all individual who are child
  if (familyData.children) {
    for (const membership of family.entity.memberships) {
      membership.individual.childId = familyData.children[membership.individual.id] || 0
    }
  }
  return family
}

export class LegalRepresentative {
  constructor(
    public role: EntityRole,
    public individual: Individual,
    public notAllowed: boolean,
    public isFamilyRepresentative: boolean = false
  ) {
  }
}

export class LegalRepresentatives {
  constructor(
    public familyLegalRepresentatives: EntityMembership[],
    public childLegalRepresentatives: LegalRepresentative[]
  ) {
  }

  public getChildLegalRepresentatives(): LegalRepresentative[] {
    // retourne les représentants légaux d'un enfant
    // Ceux de la famille à moins qu'il soit marqué comme "not autorisé" dans ceux de l'enfant
    // Ceux de l'enfant marqués comme autorisés
    const legalRepresentatives: LegalRepresentative[] = []
    const individualIds: number[] = []
    const notAllowedIndividuals = this.childLegalRepresentatives.filter(
      (elt: LegalRepresentative) => elt.notAllowed
    ).map(
      (elt: LegalRepresentative) => elt.individual.id
    )
    for (const familyRepresentative of this.familyLegalRepresentatives) {
      if (notAllowedIndividuals.indexOf(familyRepresentative.individual.id) === -1) {
        // La personne ne fait pas partie de celles qui ne sont pas autorisées
        if (!existsIn([familyRepresentative.individual.id], individualIds)) {
          legalRepresentatives.push(
            new LegalRepresentative(
              familyRepresentative.role,
              familyRepresentative.individual,
              false,
              true
            )
          )
          individualIds.push(familyRepresentative.individual.id)
        }
      }
    }
    // On ajoute les personnes supplémentaires comme LegalRepresentatives
    for (const childRepr of this.childLegalRepresentatives) {
      if ((!childRepr.notAllowed) && !existsIn([childRepr.individual.id], individualIds)) {
        legalRepresentatives.push(childRepr)
        individualIds.push(childRepr.individual.id)
      }
    }
    return legalRepresentatives
  }

  public getAllChildLegalRepresentatives(): LegalRepresentative[] {
    // retourne tous les représentants légaux d'un enfant autorisés ou non
    const individualIds: number[] = []
    const legalRepresentatives: LegalRepresentative[] = []
    const notAllowedIndividuals = this.childLegalRepresentatives.filter(
      (elt: LegalRepresentative) => elt.notAllowed
    ).map(
      (elt: LegalRepresentative) => elt.individual.id
    )
    for (const familyRepresentative of this.familyLegalRepresentatives) {
      if (!existsIn([familyRepresentative.individual.id], individualIds)) {
        legalRepresentatives.push(
          new LegalRepresentative(
            familyRepresentative.role,
            familyRepresentative.individual,
            notAllowedIndividuals.indexOf(familyRepresentative.individual.id) >= 0,
            true
          )
        )
        individualIds.push(familyRepresentative.individual.id)
      }
    }
    // On ajoute les personnes supplémentaires comme LegalRepresentatives
    for (const childRepr of this.childLegalRepresentatives) {
      if ((!childRepr.notAllowed) && !existsIn([childRepr.individual.id], individualIds)) {
        legalRepresentatives.push(childRepr)
        individualIds.push(childRepr.individual.id)
      }
    }
    return legalRepresentatives
  }
}

export function makeLegalRepresentative(jsonData: any = null): LegalRepresentative {
  if (jsonData === null) {
    jsonData = {}
  }
  return new LegalRepresentative(
    makeEntityRole(jsonData.role),
    makeIndividual(jsonData.individual),
    !!jsonData.not_allowed
  )
}

export function makeLegalRepresentatives(jsonData: any = null): LegalRepresentatives {
  if (jsonData === null) {
    jsonData = {}
  }
  let childLegalRepresentatives: LegalRepresentative[] = []
  if (jsonData.child_legal_representatives) {
    childLegalRepresentatives = jsonData.child_legal_representatives.map(
      (elt: any) => makeLegalRepresentative(elt)
    )
  }
  let familyLegalRepresentatives: EntityMembership[] = []
  if (jsonData.family_legal_representatives) {
    familyLegalRepresentatives = jsonData.family_legal_representatives.map(
      (elt: any) => makeEntityMembership(elt)
    )
  }
  return new LegalRepresentatives(
    familyLegalRepresentatives,
    childLegalRepresentatives
  )
}

export class LegalRepresentativeEx {
  constructor(
    public id: number,
    public role: EntityRole,
    public individual: Individual,
    public childIndividual: Individual,
    public notAllowed: boolean
  ) {
  }

  public toEntityMembership(): EntityMembership {
    const membership = makeEntityMembership()
    membership.legalRepresentativeId = this.id
    membership.individual = this.individual
    membership.role = this.role
    membership.comments = this.childIndividual.firstAndLastName()
    return membership
  }
}

export function makeLegalRepresentativeEx(jsonData: any = null): LegalRepresentativeEx {
  if (jsonData === null) {
    jsonData = {}
  }
  return new LegalRepresentativeEx(
    jsonData.id || 0,
    makeEntityRole(jsonData.role),
    makeIndividual(jsonData.individual),
    makeIndividual(jsonData.child_individual),
    !!jsonData.not_allowed
  )
}

export function makeFamilyMembership(jsonData: any = null): EntityMembership {
  if (jsonData === null) {
    jsonData = {}
  }
  const membership = makeEntityMembership(jsonData)
  if (jsonData.child) {
    membership.individual.childId = jsonData.child
  }
  return membership
}

export class FamilyLevelHistory {
  constructor(
    public id: number,
    public value: number|null,
    public updatedOn: Date|null,
    public order: number,
    public confirmed: boolean,
    public refused: boolean,
    public updatedBy: string
  ) {
  }
}

export function makeFamilyLevelHistory(jsonData: any = null): FamilyLevelHistory {
  if (jsonData === null) {
    jsonData = {}
  }
  return new FamilyLevelHistory(
    jsonData.id || 0,
    jsonData.value || null,
    jsonData.updated_on || null,
    jsonData.order || 0,
    !!jsonData.confirmed,
    !!jsonData.refused,
    jsonData.updated_by || ''
  )
}
