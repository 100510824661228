<template>
  <span class="create-sale-modal" v-if="hasPerm('payments.add_sale')">
    <b-modal
      id="bv-modal-create-sale"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onOk"
      ok-variant="primary"
      ok-title="Créer la vente"
      @shown="initModal()"
      dialog-class="modal-lg"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-money-bill"></i> Saisie une vente extérieure</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                id="label-group"
                label="Libellé"
                label-for="label"
              >
                <b-form-input id="label" v-model="label" required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="date-group"
                label="Date"
                label-for="date"
                description=""
              >
                <b-form-input type="date" id="date" v-model="date">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="price-group"
                label="Montant"
                label-for="price"
                description="Montant"
                required
              >
                <b-form-input id="price" type="number" v-model="price" step="0.01" min="0"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label=""
                label-for="auto_invoice"
                description="Si coché alors une facture sera créé automatiquement"
              >
                <b-form-checkbox
                  id="auto_invoice"
                  v-model="autoInvoice"
                  name="auto_invoice"
                  :value="true"
                  :unchecked-value="false"
                >
                  Facturation automatique
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                id="school-year-group"
                label="Année scolaire"
                label-for="school-year"
              >
                <b-form-select v-model="selectedSchoolYear" id="school-year">
                  <b-select-option v-for="elt of schoolYears" :key="elt.id" :value="elt">
                    {{ elt.name }}
                  </b-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="general-account-group"
                label="Code comptable"
                label-for="general-account"
              >
                <b-form-select v-model="selectedGeneralAccount" id="general-account">
                  <b-select-option v-for="elt of generalAccounts" :key="elt.id" :value="elt">
                    {{ elt.name }}<span v-if="elt.label"> - {{ elt.label }}</span>
                  </b-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="analytic-account-group"
                label="Code analytique"
                label-for="analytic-account"
              >
                <b-form-select v-model="selectedAnalyticAccount" id="analytic-account">
                  <b-select-option v-for="elt of analyticAccounts" :key="elt.id" :value="elt">
                    {{ elt.label || elt.name }}
                  </b-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi } from '@/utils/http'

export default {
  name: 'create-sale-modal',
  mixins: [BackendMixin],
  props: {
    entity: null,
  },
  data() {
    return {
      price: 0,
      label: '',
      errorText: '',
      selectedGeneralAccount: makeGeneralAccount(),
      selectedAnalyticAccount: makeAnalyticAccount(),
      selectedSchoolYear: null,
      date: moment().format('YYYY-MM-DD'),
      autoInvoice: false,
      allAnalyticAccounts: [],
      allGeneralAccounts: [],
      schoolYears: [],
    }
  },
  computed: {
    analyticAccounts() {
      return [makeAnalyticAccount()].concat(this.allAnalyticAccounts)
    },
    generalAccounts() {
      let generalAccounts = this.allGeneralAccounts
      if (generalAccounts) {
        generalAccounts = generalAccounts.filter(elt => elt.isPayment)
        return [makeGeneralAccount()].concat(generalAccounts)
      }
      return []
    },
  },
  watch: {
    entity: function(entryType) {
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async initModal() {
      await this.loadSchoolYears()
      await this.loadAnalyticAccounts()
      await this.loadGeneralAccounts()
    },
    async loadAnalyticAccounts() {
      let url = '/api/analytic-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allAnalyticAccounts = resp.data.map(
          elt => makeAnalyticAccount(elt)
        )
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadGeneralAccounts() {
      let url = '/api/general-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allGeneralAccounts = resp.data.map(
          elt => makeGeneralAccount(elt)
        )
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt)).filter(elt => elt.isActive)
        if (this.schoolYears.length) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    isDisabled() {
      return (this.price <= 0) || (!this.label) || (!this.date) || (!this.selectedSchoolYear)
    },
    async onOk() {
      this.errorText = ''
      try {
        const url = '/api/entity/' + this.entity.id + '/create-sale/'
        const backendApi = new BackendApi('post', url)
        let data = {
          base_price: this.price,
          label: this.label,
          apply_on: moment(this.date).format('YYYY-MM-DD'),
          general_account: this.selectedGeneralAccount.id || null,
          analytic_account: this.selectedAnalyticAccount.id || null,
          school_year: this.selectedSchoolYear.id,
          auto_invoice: this.autoInvoice,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide('bv-modal-create-sale')
        this.selectedGeneralAccount = makeGeneralAccount()
        this.selectedAnalyticAccount = makeAnalyticAccount()
        this.date = moment().format('YYYY-MM-DD')
        this.price = 0
        this.label = ''
        await this.addSuccess('La vente a été créée avec succès')
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
}
</script>
<style lang="less">
</style>
