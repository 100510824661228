<template>
  <div class="home">
    <page-header title="Accueil" icon="fa fa-home"></page-header>
    <b-row>
      <b-col cols="5">
        <div class="home1">
          <ul class="app-menu">
            <li>
              <b>
                millibase.net - version {{ version }}
              </b>
              <a @click.prevent="reloadApp"><i class="fa fa-redo"></i></a>
            </li>
            <li v-if="hasPerm('families.view_familyentity')">
              <router-link :to="{ name: 'families-list', }"><i class="fa-solid fa-people-roof"></i> Familles</router-link>
            </li>
            <li v-if="hasPerm('people.view_contact')">
              <router-link :to="{ name: 'entities-list', }"><i class="fa fa-address-book"></i> Annuaire</router-link>
            </li>
            <li v-if="activityMenus.length === 0 && hasPerm('activities.view_coreactivity')">
              <router-link :to="{ name: 'activities-list', }">
                <i class="fas fa-chess-knight"></i> Activités
              </router-link>
            </li>
            <li
              v-for="menu of activityMenus"
              :key="menu.id"
            >
              <router-link :to="{ name: 'activities-list', query: { category: menu.category, }, }">
                <i :class="menu.icon"></i> {{ menu.label }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seance')">
              <router-link :to="{ name: 'youth-homes-list', }">
                <i :class="youthHomeIcon"></i> {{youthHomeLabel}}
              </router-link>
            </li>
            <li v-if="isPortalActive && hasPerm('portal')">
              <router-link :to="{ name: 'family-portal-home', }">
                <i class="fa-solid fa-house-signal"></i> Portail Familles
              </router-link>
            </li>
            <li v-if="nursery && hasPerm('nursery.view_')">
              <router-link :to="{ name: 'nursery-home', }">
                <i class="fa fa-baby-carriage"></i> Crèche
              </router-link>
            </li>
            <li v-if="isStoreActive && hasPerm('store.view_article')">
              <router-link :to="{ name: 'store-articles', }">
                <i class="fa fa-dice"></i> Ludothèque
              </router-link>
            </li>
            <li v-if="isPublicationActive && hasPerm('publications.view_publication')">
              <router-link :to="{ name: 'publications-home', }">
                <i class="fa fa-book-open-reader"></i> {{ publicationTitle }}
              </router-link>
            </li>
            <li v-if="hasPerm('payments')">
              <router-link :to="{ name: 'invoices-synthesis', }">
                <i class="fa fa-euro-sign"></i> Facturation
              </router-link>
            </li>
            <li v-if="hasOnePerm(['reports', 'stats'])">
              <router-link :to="{ name: 'stats-home', }">
                <i class="fas fa-chart-pie"></i> Statistiques
              </router-link>
            </li>
            <li v-if="hasPerm('home.view_structure')">
              <router-link :to="{ name: 'tools-home', }">
                <i class="fa fa-tools"></i> Outils
              </router-link>
            </li>
            <li v-if="hasPerm('home.view_structure')">
              <router-link :to="{ name: 'admin-home', }">
                <i class="fa fa-cogs"></i> Administration
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="home2">
          <ul class="app-menu">
            <li class="text-right">
              <a href @click.prevent="showContactSupport()" class="underline">
                <i class="fa fa-user-edit"></i> Contacter le support
              </a>
              &nbsp;
              <a href @click.prevent="showOnlineHelp()" class="underline show-online-help">
                <i class="fa fa-info-circle"></i> Aide en ligne
              </a>
            </li>
            <li v-if="canViewNotes">
              <b-row>
                <b-col><b><i class="fa fa-sticky-note"></i> Notes</b></b-col>
                <b-col cols="9" class="text-right" v-if="!editNote">
                  <router-link :to="{ name: 'notes', }" class="underline">
                    <i class="fa fa-th-list"></i> Voir toutes les notes
                  </router-link>
                  &nbsp;
                  <a href class="underline" @click.prevent="editNote = true" v-if="canAddNote">
                    <i class="fa fa-plus"></i> Ajouter une note
                  </a>
                </b-col>
              </b-row>
            </li>
          </ul>
          <div v-if="canViewNotes">
            <div v-if="editNote">
              <note-editor :home="true" @note-added="onNoteAdded" @cancel="editNote = false">
              </note-editor>
            </div>
            <div v-else>
              <loading-gif :loading-name="notesLoading"></loading-gif>
              <note-items
                :items="notes"
                :home="true"
                v-if="!isLoading(notesLoading)"
                @item-closed="onItemClosed($event)"
              ></note-items>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <confirm-modal
      name="hide-note"
      :title="hideNoteTitle"
      :text="hideNoteText"
      :object="selectedNote"
      @confirmed="onHideNote"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import NoteItems from '@/components/Notes/NoteItems'
import NoteEditor from '@/components/Notes/NoteEditor'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeNote } from '@/types/notes'
import { BackendApi } from '@/utils/http'

export default {
  name: 'home',
  components: {
    LoadingGif,
    NoteItems,
    NoteEditor,
    PageHeader,
    ConfirmModal,
  },
  mixins: [BackendMixin],
  mounted() {
    this.loadNotes()
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      notesLoading: 'notes-loading',
      notes: [],
      selectedNote: null,
      editNote: false,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    nursery() {
      return store.getters.config.nursery
    },
    isStoreActive() {
      return store.getters.config.isStoreActive
    },
    isPublicationActive() {
      return store.getters.config.isPublicationActive
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    isPortalActive() {
      return store.getters.config.isPortalActive
    },
    activityMenus() {
      if (this.hasPerm('activities.view_coreactivity')) {
        return store.getters.config.activityMenus
      }
      return []
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    selectedNoteTitle() {
      return this.selectedNote ? this.selectedNote.title : ''
    },
    hideNoteTitle() {
      if (this.selectedNoteTitle) {
        if (this.selectedNote.page) {
          return 'Ne plus voir la note sur l\'accueil'
        } else {
          return 'Suppression d\'une note'
        }
      }
      return ''
    },
    hideNoteText() {
      if (this.selectedNoteTitle) {
        if (this.selectedNote.page) {
          return 'La note ' + this.selectedNoteTitle + ' ne sera plus visible sur votre page d\'accueil'
        } else {
          return 'La note ' + this.selectedNoteTitle + ' sera supprimée de millibase'
        }
      }
      return ''
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
    canAddNote() {
      return this.hasPerm('notes.add_note')
    },
  },
  methods: {
    reloadApp() {
      // force to reload the app after deleting the browser cache for this page
      // the forceReload flag is deprecated : so try without in case of error
      try {
        // tslint:disable-next-line:deprecation
        window.location.reload(true)
      } catch (e) {
        window.location.reload()
      }
    },
    showOnlineHelp() {
      this.$bvModal.show('bv-online-help')
    },
    showContactSupport() {
      this.$bvModal.show('bv-contact-support')
    },
    async loadNotes() {
      if (this.canViewNotes) {
        const url = '/notes/api/notes/?home=home'
        this.startLoading(this.notesLoading)
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.notes = resp.data.results.map(elt => makeNote(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.notesLoading)
      }
    },
    onNoteAdded(note) {
      this.editNote = false
      this.notes = [note].concat(this.notes)
    },
    onItemClosed(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:hide-note')
    },
    async onHideNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/remove-note-from-home/' + this.selectedNote.id + '/'
        let backendApi = new BackendApi('post', url)
        if (!this.selectedNote.page) {
          // Pour les notes seulement visibles sur l'accueil. On les supprime
          url = '/notes/api/notes/' + this.selectedNote.id + '/'
          let backendApi = new BackendApi('delete', url)
        }
        try {
          const data = {}
          const resp = await backendApi.callApi(data)
          this.notes = this.notes.filter(elt => elt.id !== this.selectedNote.id)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<style lang="less">
.show-online-help  {
  margin-right: 5px;
}
.home1 {
  padding: 9px 10px;
  height: 100vh;
}
.home2 {
  padding: 2px 10px;
  background: #eee;
  height: 100vh;
}
</style>
