export const notesModule = {
  state: {
    notesCount: 0,
  },
  getters: {
    notesCount: (state: any) => {
      return state.notesCount
    },
  },
  mutations: {
    setNotes(state: any, notesCount: number) {
      state.notesCount = notesCount
    },
    resetNotes(state: any) {
      state.notesCount = 0
    },
  },
  modules: {
  },
}

export default notesModule
