import { monthsAgo, today } from '@/utils/dates'
import { compareDays } from '@/utils/sorting'

export const APPLY_TO_INDIVIDUALS = 'individuals'
export const APPLY_TO_CHILDREN = 'children'
export const APPLY_TO_ADULTS = 'adults'
export const APPLY_TO_ENTITIES = 'entities'
export const APPLY_TO_FAMILIES = 'families'
export const APPLY_TO_ORGANIZATIONS = 'organizations'
export const APPLY_TO_ENTITY_MEMBERS = 'entity-members'
export const APPLY_TO_BABIES = 'babies'
export const APPLY_TO_CHILDREN_NOT_BABIES = 'children-not-babies'

export enum FieldType {
  Text = 1,
  Choices = 2,
  Boolean = 3,
  Integer = 4,
  Decimal = 5,
  Date = 6,
  Encrypted = 7
}

export class FieldChoice {
  constructor(
    public id: number,
    public text: string,
    public isDefault: boolean
  ) {
  }
}

export class Field {
  constructor(
    public id: number,
    public fieldType: FieldType,
    public text: string,
    public isMandatory: boolean,
    public allowComments: boolean,
    public helpText: string,
    public allowSuggestions: boolean,
    public suggestionFor: number,
    public highlightIfTrue: boolean,
    public initTrue: boolean,
    public validityInMonths: number,
    public choices: FieldChoice[] = [],
    public regex: string,
    public autocomplete: boolean
  ) {
  }
}

export class FieldsGroup {
  constructor(
    public id: number,
    public name: string,
    public applyTo: string[],
    public included: boolean,
    public includedInAddress: boolean,
    public fields: Field[] = []
  ) {
  }
}

export class FieldValue {
  constructor(
    public field: number,
    public value: number,
    public text: string,
    public comments: string
  ) {
  }
}

export class FieldValues {
  constructor(
    public fieldValues: FieldValue[]
  ) {
  }

  public getText(field: Field): string {
    for (let fieldValue of this.fieldValues) {
      if (fieldValue.field === field.id) {
        return fieldValue.text
      }
    }
    return ''
  }

  public getValue(field: Field): any {
    for (let fieldValue of this.fieldValues) {
      if (fieldValue.field === field.id) {
        return fieldValue.value
      }
    }
    return null
  }

  public isValid(field: Field): any {
    if ((field.fieldType === FieldType.Date) && (field.validityInMonths > 0)) {
      const value = this.getValue(field)
      if (value) {
        const nMonthsAgo: string = monthsAgo(today(), -field.validityInMonths)
        return compareDays(nMonthsAgo, value) < 0
      }
    }
    return true
  }

  public getComments(field: Field): string {
    if (field.allowComments) {
      for (let fieldValue of this.fieldValues) {
        if (fieldValue.field === field.id) {
          return fieldValue.comments
        }
      }
    }
    return ''
  }
}

export function makeFieldChoice(jsonData: any = null): FieldChoice {
  if (jsonData === null) {
    jsonData = {}
  }
  return new FieldChoice(
    jsonData.id || 0,
    jsonData.text || '',
    !!jsonData.is_default
  )
}

export function makeField(jsonData: any = null): Field {
  if (jsonData === null) {
    jsonData = {}
  }
  const choices: any[] = jsonData.choices || []
  return new Field(
    jsonData.id || 0,
    jsonData.field_type || 0,
    jsonData.text || '',
    !!jsonData.is_mandatory,
    !!jsonData.allow_comments,
    jsonData.help_text || '',
    !!jsonData.allow_suggestions,
    jsonData.suggestion_for || 0,
    !!jsonData.highlight_if_true,
    !!jsonData.init_true,
    jsonData.validity_in_months || 0,
    choices.map((elt: any) => makeFieldChoice(elt)),
    jsonData.regex || '',
    !!jsonData.autocomplete
  )
}

export function makeFieldsGroup(jsonData: any = null): FieldsGroup {
  if (jsonData === null) {
    jsonData = {}
  }
  const fields: any[] = jsonData.fields || []
  return new FieldsGroup(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.apply_to || [],
    !!jsonData.included,
    !!jsonData.included_in_address,
    fields.map((elt: any) => makeField(elt))
  )
}

export function makeFieldValues(jsonData: any = null): FieldValues {
  if (jsonData === null) {
    jsonData = {}
  }
  let fieldValues: FieldValue[] = []
  for (let elt of jsonData) {
    fieldValues.push(
      new FieldValue(elt.field, elt.value, elt.text, elt.comments)
    )
  }
  return new FieldValues(fieldValues)
}
