<template>
  <div class="message-bar">
    <transition-group name="list" v-if="messages.length">
      <div v-for="message in messages" :key="message.id">
        <div class="message" :class="[message.levelName()]">
          <div class="message-item" v-html="htmlText(message)"></div>
          <div
            class="close-messages"
            @click.prevent="onClose"
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import store from '@/store'
import { htmlText } from '@/utils/html'
import { mapActions } from 'vuex'

export default {
  name: 'MessageBar',
  created() {
    store.watch(
      (state, getters) => getters.messages,
      (newValue, oldValue) => {}
    )
  },
  computed: {
    messages() {
      return store.getters.messages
    },
    hasMessages() {
      return store.getters.hasMessages
    },
  },
  methods: {
    ...mapActions(['clearMessages']),
    htmlText(message) {
      return htmlText(message.text)
    },
    onClose() {
      this.clearMessages()
    },
    isFirstMessage(message) {
      if (this.messages.length > 0) {
        return this.messages[0].id === message.id
      }
      return false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.message-bar {
  position: fixed;
  top: 30px;
  left: 20%;
  width: 60%;
  z-index: 100;
}

.message-bar .message {
  padding: 5px 20px;
  z-index: 100;
  text-align: left;
  border-radius: 8px;
  margin-bottom: 4px;
}

.message-bar .message.error {
  background: #f78888;
  color: #000;
}

.message-bar .message.warning {
  background: #f3d250;
  color: #000;
}

.message-bar .message.success {
  background: #73956f;
  color: #000;
}

.message-bar .message.info {
  background: #5a6268;
  color: #fff;
}

.message-item {
  display: inline-block;
  width: ~"calc(100% - 30px)";
}

.close-messages {
  cursor: pointer;
  display: inline-block;
  width: 28px;
  text-align: right;
}

.list-enter-active, .list-leave-active {
  transition: all 2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
